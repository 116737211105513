angular.module('app')
    .factory('bookingDecoratorService', ["moment", "$log", function (moment, $log) {
        var PRIVATE_NUMBER_PREFIX = '1831';

        function showWaitTime(booking) {
            var now = moment();

            return booking.requestedPickup.isBefore(now) ? true : false;
        }

        function isActive(booking) {
            return booking.status == 'AWAITING_ALLOCATION' || booking.status == 'ALLOCATED' || booking.status == 'ABANDONED' || booking.status == 'EN_ROUTE_TO_PICKUP' || booking.status == 'APPROACHING_PICKUP' || booking.status == 'ARRIVED_AT_PICKUP';
        }

        return {
            /**
             * Decorates a booking summary with additional derived fields for display purposes. This operation is
             * idempotent.
             *
             * @param booking the booking summary to decorate.
             */
            decorate: function (booking) {
                $log.debug(booking);

                if (booking.requestedPickup) { //TODO: this is a hack and needs to be fixed
                    booking.requestedPickup = moment(booking.requestedPickup);
                } else if (booking.requestedPickupDateTime) {
                    booking.requestedPickup = moment(booking.requestedPickupDateTime);
                }
                booking.showWaitTime = showWaitTime(booking);
                booking.isActive = isActive(booking);
            }
        };
    }]);
